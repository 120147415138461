import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router } from "react-router-dom";
import Landing from "./pages/Landing";
import Referral from "./pages/Referral";

function App() {
  return (
    <Router>
      <Route path="/" exact component={Landing} />
      <Route path="/referral" exact component={Referral} />
    </Router>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);
