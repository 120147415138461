import React from "react";

const archive = () =>
  fetch("https://europe-west1-productfriday-com.cloudfunctions.net/archive")
    .then(res => res.json())
    .then(json => json);

export default class Archive extends React.Component {
  state = { loading: false, data: [], slice: 6 };

  async componentDidMount() {
    this.setState({ loading: true });
    const data = await archive();
    this.setState({ data, loading: false });
  }

  readMore = () => {
    const { slice, data } = this.state;
    if (slice >= data.length) return;
    this.setState({ slice: slice + 6 });
  };

  render() {
    const { data, slice, loading } = this.state;
    return (
      <div id="archive" style={{ margin: "-50px auto 0px", maxWidth: "900px" }}>
        {data && data.length ? (
          data
            .map(i => (
              <div className="ArchiveItem" key={i.title}>
                <a
                  href={i.link}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="ArchiveDate">{i.date}</div>
                  <div className="ArchiveTitle">{i.title}</div>
                  <div style={{ maxWidth: "900px" }}>{i.snippet}</div>
                </a>
              </div>
            ))
            .slice(0, slice)
        ) : (
          <p>Fetching stuff from the internet...</p>
        )}
        <div style={{ float: "right" }}>
          {slice < data.length ? (
            <button className="LoadMoreButton" onClick={this.readMore}>
              Load more goodness
            </button>
          ) : !loading ? (
            <div>You really read everything, huh?</div>
          ) : null}
        </div>
      </div>
    );
  }
}
