import qs from "qs";

const parseUrlQuery = query => qs.parse(query);

const API_URL = "https://europe-west1-productfriday-com.cloudfunctions.net/";

const apiRequest = async (path, params) => {
  const { query, ...rest } = params;
  const url = API_URL + path + (query ? "?" + qs.stringify(query) : "");
  const res = await fetch(url, rest);
  const data = await res.json();
  return data;
};

export { parseUrlQuery, apiRequest };
