import React, { useRef } from "react";
import ReactGA from "react-ga";
import Archive from "../components/Archive";

import "../styles/index.css";
import { parseUrlQuery, apiRequest } from "../util";
import { parse } from "parse-form";

const onFormSubmit = async e => {
  window.open(
    "https://tinyletter.com/productfriday",
    "popupwindow",
    "scrollbars=yes,width=800,height=600"
  );
  ReactGA.event({
    category: "signup",
    action: "Signed up to mailing list"
  });
  const query = parseUrlQuery(window.location.search.substr(1));
  const { refId } = query;
  const { email } = parse(e.target).body;
  const res = await apiRequest("signup", {
    method: "POST",
    headers: { "Content-type": "application/json" },
    body: JSON.stringify({ refId, email })
  });
  const data = await res.json();
  console.log(data);
  return data;
};

export default function Landing() {
  const archiveRef = useRef(null);
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="App WideColumn">
        <img
          className="VectorTop"
          src="/assets/top-left-vector.svg"
          alt="geometric"
        />
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "80vh"
            }}
          >
            <div
              style={{
                maxWidth: 700,
                background: "rgba(255,255,255,0.5)",
                marginBottom: 30
              }}
            >
              <div className="LandingBigText">
                Product<span style={{ color: "#FC6472" }}>Friday</span>.com
              </div>
              <p className="LandingSubText">
                current trends, tools and libraries combined with the latest
                news for designers, developers and product owners.
              </p>
            </div>
            <div>
              <form
                className="inputrow"
                action="https://tinyletter.com/productfriday"
                method="post"
                target="popupwindow"
                onSubmit={e => {
                  onFormSubmit(e).catch(err => console.error(err));
                  return true;
                }}
              >
                <input
                  className="Input"
                  type="email"
                  name="email"
                  placeholder="Enter email"
                />
                <button className="Button" type="submit">
                  Gimmie
                </button>
              </form>
            </div>
            <img
              className="SideVector"
              src="/assets/long-right.svg"
              alt="side vector"
            />
          </div>
          <div>
            <img
              style={{ maxWidth: "100%", marginBottom: 15 }}
              src="/assets/mockup&text.svg"
              alt="phone mockup"
            />
          </div>
        </div>
        <div style={{ width: "100%" }} ref={archiveRef}>
          <Archive />
        </div>
        <div
          style={{
            margin: "150px auto 0"
          }}
        >
          <div style={{ margin: "0 auto" }}>
            <div className="LandingBigText">
              <span style={{ color: "#FC6472" }}>Fri</span>-nally, some good
              product news!
            </div>
            <p className="LandingSubText">
              Free, curated weekly content with added personality.
            </p>
          </div>
          <form
            style={{ marginTop: 30 }}
            className="inputrow"
            action="https://tinyletter.com/productfriday"
            method="post"
            target="popupwindow"
            onSubmit={e => {
              onFormSubmit(e).catch(err => console.error(err));
              return true;
            }}
          >
            <input
              className="Input"
              type="email"
              name="email"
              placeholder="Email address.."
            />
            <button className="Button" type="submit">
              Gimmie
            </button>
          </form>
        </div>
        <footer className="WideColumn Footer">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://p11.co/"
            className="CuratedBy"
          >
            <span style={{ zIndex: 15 }}>Curated by Perspektives</span>
          </a>
          <img
            className="VectorBottomLeft"
            src="/assets/bottom-left-vector.svg"
            alt="geometric"
          />
          <img
            className="VectorBottomRight"
            src="/assets/bottom-right-vector.svg"
            alt="geometric"
          />
        </footer>
      </div>
    </div>
  );
}
