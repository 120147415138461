import React, { useState, useEffect } from "react";
import ReactGA from "react-ga";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/index.css";

import { parseUrlQuery, apiRequest } from "../util";
import * as EmailValidator from "email-validator";

const emailBody = `Hey!

I'm subscribed to Product Friday, it's a pretty awesome weekly email about the digital product world. Have you heard of it?
Here's my referral link, check it out!`;

// @TODO change to use https://www.npmjs.com/package/clipboard
// or similar
function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    var successful = document.execCommand("copy");
    var msg = successful ? "successful" : "unsuccessful";
    console.log("Fallback: Copying text command was " + msg);
  } catch (err) {
    console.error("Fallback: Oops, unable to copy", err);
  }

  document.body.removeChild(textArea);
}

function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return;
  }
  navigator.clipboard.writeText(text).then(
    function() {
      console.log("Async: Copying to clipboard was successful!");
    },
    function(err) {
      console.error("Async: Could not copy text: ", err);
    }
  );
}

const shareEmail = (emails, shareLink) => {
  const array = emails.split(",");
  const filtered = array.filter(
    email => EmailValidator.validate(email.trim()) === true
  );
  if (filtered.length !== array.length) {
    toast.warn("Make sure to check that all emails are correct!");
  }
  document.querySelector(".mailtoLink").click();

  ReactGA.event({
    category: "referral",
    action: "Sent referrals"
  });
};

const scale = [0, 3, 7, 15, 25, 50, 500, 5000];
const getProgress = (val = 0) => {
  const step = scale.reduce((res, num, idx) => {
    return val >= num ? idx : res;
  }, 0);
  const stepsCount = scale.length - 1;
  const stepValue = scale[step];
  const stepLength = 1 / stepsCount;
  const progressInStep = (val - stepValue) / (scale[step + 1] - scale[step]);
  // console.log({
  //   stepsCount,
  //   stepLength,
  //   progressInStep,
  //   step,
  //   scale1: scale[step + 1],
  //   scale2: scale[step]
  // });
  return stepLength * (step + progressInStep);
};

const copyLink = text => {
  copyTextToClipboard(text);
  toast.success("Link copied!", {
    position: toast.POSITION.TOP_CENTER
  });
};

const query = parseUrlQuery(window.location.search.substr(1));
console.log("query", query);

export default function Referral() {
  const [emails, setEmails] = useState();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  console.log("user", user);
  useEffect(() => {
    // api call
    setLoading(true);
    const api = async () => {
      const { email } = query;
      const user = await apiRequest("user", { query: { email } });
      if (!EmailValidator.validate(user.email)) {
        throw Error("Invalid email!");
      }
      setUser(user);
    };
    api().catch(err => {
      console.error(err);
      setError("Invalid email :( No referral link available");
    });
    setLoading(false);
  }, []);

  const shareLink = error
    ? error
    : loading || !user
    ? "Loading referral link..."
    : `https://productfriday.com/?refId=${user && user.refId}`;
  console.log("shareLink", shareLink);
  const progress = getProgress(user && user.invited);

  const shareUrlEncoded = encodeURIComponent(shareLink);
  const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${shareUrlEncoded}`;
  const twitterUrl = `https://twitter.com/intent/tweet?url=${shareUrlEncoded}`;
  const linkedinUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${shareUrlEncoded}`;

  const mailToLink =
    emails && emails.length
      ? `mailto:${emails}?subject=Check+out+Product+Friday&body=${encodeURIComponent(
          emailBody + "\n\n" + shareLink
        )}`
      : null;

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <ToastContainer toastClassName="customToast" />
      <div className="App WideColumn">
        <div className="shareTags">
          <img
            className="TagVectorBottomLeft"
            src="/assets/sharetag1.svg"
            alt="sharetag"
          />
          <img
            className="TagVectorTopLeft"
            src="/assets/sharetag2.svg"
            alt="share tag"
          />
          <img
            className="TagVectorTopRight"
            src="/assets/sharetag3.svg"
            alt="share tag"
          />
          <img
            className="TagVectorBottomRight"
            src="/assets/sharetag4.svg"
            alt="share tag"
          />
        </div>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              height: "80vh"
            }}
          >
            <div className="WideColumn">
              <p className="SmallTitleText">Introducing</p>
              <div className="BigText">
                Share Product <span style={{ color: "#FC6472" }}>Friday</span> &
                Get Swag
              </div>
              <p className="SubText" style={{ maxWidth: 700 }}>
                Sharing is caring, and swag is awesome. Be that, showing others
                the joys of Friday’s and snag yourself some goodies in the
                process.
              </p>

              <div className="inputrow upperlink">
                <input
                  className="Input"
                  placeholder="sharelinkgoeshere"
                  disabled
                  value={shareLink}
                />
                <button
                  disabled={Boolean(error)}
                  className="Button"
                  onClick={() => copyLink(shareLink)}
                >
                  Copy Link
                </button>
              </div>
            </div>
          </div>
          <div className="WideColumn HowItWorksContainer">
            <p className="SmallTitleText title">Here's how it works</p>
            <p className="description">
              Upgrade someone’s Friday, get swagged out and track your progress
              with real-time updates.
            </p>
            <div className="Row HowItWorks">
              <div>
                <div className="number">1</div>
                <img src="/assets/bx_send.svg" alt="share" />
                <div className="text">Share</div>
              </div>
              <i className="la la-arrow-right" />
              <div>
                <div className="number">2</div>
                <img src="/assets/bx_gift.svg" alt="gift" />
                <div className="text">Profit</div>
              </div>
              <i className="la la-arrow-right" />
              <div>
                <div className="number">3</div>
                <img src="/assets/bx_repeat.svg" alt="repeat" />
                <div className="text">Repeat</div>
              </div>
            </div>
          </div>

          {!error ? (
            <p className="NormalTitle">
              <span style={{ color: "#FC6472" }}>{user && user.email}</span>'s
              referral progression
            </p>
          ) : (
            <p className="NormalTitle">Referral progression</p>
          )}
          <div style={{ position: "relative" }}>
            <div
              style={{ height: "4px", background: "#eee" }}
              className="progressBar"
            >
              <div
                style={{
                  width: `${progress * 100}%`,
                  borderBottom: "4px solid red",
                  transition: "width 1s"
                }}
              />
            </div>
            <img
              style={{ width: "100%", margin: "0 auto 15px" }}
              src="/assets/stages.svg"
              alt="stages"
            />
            {!user || user.invited === 0 ? (
              <div className="WideColumn">
                <p className="NormalText">No friends have joined.. yet!</p>
                <p style={{ marginTop: 30 }} className="NormalText">
                  Bring some Product to their Friday's!
                </p>
              </div>
            ) : (
              <div className="WideColumn">
                <p className="referralCount">{user.invited}</p>
                <p className="NormalText">
                  people have joined through your referrals!
                </p>
              </div>
            )}
          </div>
          <div
            className="redBackground"
            style={{ maxWidth: "100%", marginTop: 100 }}
          >
            <p className="NormalTitle">Share your link</p>
            <p
              style={{ color: "3F3F3F", marginBottom: 15 }}
              className="NormalText"
            >
              Spread the word & earn the rewards. Everyone loves a new mug.
            </p>
            <div className="inputrow">
              <input
                className="Input"
                type="email"
                name="email"
                disabled
                placeholder="sharelinkgoeshere"
                value={shareLink}
              />
              <button
                disabled={Boolean(error)}
                className="Button"
                onClick={() => copyLink(shareLink)}
              >
                Copy Link
              </button>
            </div>
          </div>
          <div className="shareBg">
            <p className="NormalTitle">Share via email</p>
            <p
              style={{ color: "3F3F3F", marginBottom: 15 }}
              className="NormalText"
            >
              Just enter the emails below and we’ll handle the rest.
            </p>
            <div className="inputrow">
              <input
                className="Input"
                type="email"
                name="email"
                placeholder="emailone@email.com, emailtwo@email.com"
                value={emails}
                onChange={e => setEmails(e.target.value)}
              />
              <button
                disabled={Boolean(error)}
                className="Button"
                onClick={() => shareEmail(emails, shareLink)}
              >
                Send
              </button>
            </div>
            <p className="TooltipText">Seperate emails with a comma.</p>
            {mailToLink && (
              <a
                href={mailToLink}
                target="_blank"
                rel="noopener noreferrer"
                className="mailtoLink"
              />
            )}
          </div>
          {!error && (
            <div className="shareBg">
              <p className="NormalTitle">Share on social media(s)</p>
              <p
                style={{ color: "3F3F3F", marginBottom: 15 }}
                className="NormalText"
              >
                Go for the big haul and share it to the masses.
              </p>
              <a target="_blank" rel="noopener noreferrer" href={facebookUrl}>
                <img
                  className="icon"
                  src="/assets/facebook.svg"
                  alt="Facebook"
                />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={twitterUrl}>
                <img className="icon" src="/assets/twitter.svg" alt="Twitter" />
              </a>
              <a target="_blank" rel="noopener noreferrer" href={linkedinUrl}>
                <img
                  className="icon"
                  src="/assets/linkedin.svg"
                  alt="LinkedIn"
                />
              </a>
            </div>
          )}
        </div>

        <footer className="WideColumn Footer referralFooter">
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://p11.co/"
            className="CuratedBy"
          >
            <span>Curated by Perspektives</span>
          </a>
          <img
            className="RefGeoBottomRight"
            src="/assets/ref-bottom-right-vector.svg"
            alt="geometric"
          />
        </footer>
      </div>
    </div>
  );
}
